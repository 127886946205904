import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const payToFields = [
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_PAY_TYPE,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'date',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_START_DATE,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:start-date', 'Start Date')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_ADDRESS,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:address', 'Address')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    disableFilter: true,
    value: GC.GRC.PAY_TO_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:pay-to', 'Pay To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
];

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_NUMBER,
    name: G.getWindowLocale('titles:payroll-number', 'Payroll #'),
  },
  {
    type: 'date',
    value: GC.FIELD_PAYROLL_DATE_FROM,
    name: G.getWindowLocale('titles:date-from', 'Date From'),
  },
  {
    type: 'date',
    value: GC.FIELD_PAYROLL_DATE_TO,
    name: G.getWindowLocale('titles:date-to', 'Date To'),
  },
  {
    type: 'string',
    value: `${GC.FIELD_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale('titles:payroll-status', 'Payroll Status'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FULL_NAME,
    name: G.getWindowLocale('titles:driver-full-name', 'Driver Full Name'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_TRUCK_UNIT_ID,
    name: G.getWindowLocale('titles:truck', 'Truck'),
  },
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICES_COUNT,
    name: G.getWindowLocale('titles:invoices-count', 'Invoices Count'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAIN_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:main-charges', 'Main Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:deduction', 'Deduction'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel-cards', 'Fuel Cards'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:additional-payroll-pay', 'Additional Payroll Pay'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL,
    name: G.getWindowLocale('titles:expenses', 'Expenses'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_INVOICES_TOTAL,
    name: G.getWindowLocale('titles:total-driver-trip-charges', 'Total Driver Trip Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_GRAND_TOTAL,
    name: G.getWindowLocale('titles:gross-total', 'Gross Total'),
  },
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL,
    name: G.getWindowLocale('titles:advance-payment', 'Advance Payments'),
  },
  {
    type: 'string',
    value: GC.FIELD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_INTEGRATED_DATE,
    name: G.getWindowLocale('titles:integrated-date', 'Integrated Date'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INVOICES_TEL_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:tel-reference', 'TEL Reference'),
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.FIELD_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:total-trips-distance', 'Total Trips Distance'),
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.FIELD_TOTAL_RATE_DISTANCE,
    name: G.getWindowLocale('titles:total-rates-distance', 'Total Rates Distance'),
  },
  {
    type: 'string',
    value: GC.GRC.DRIVER_FLEET_VENDOR_NAME,
    name: G.getWindowLocale('titles:vendor-name', 'Vendor Name'),
  },
  ...payToFields,
];

export const VENDOR_FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_NUMBER,
    name: G.getWindowLocale('titles:payroll-number', 'Payroll #'),
  },
  {
    type: 'date',
    value: GC.FIELD_PAYROLL_DATE_FROM,
    name: G.getWindowLocale('titles:date-from', 'Date From'),
  },
  {
    type: 'date',
    value: GC.FIELD_PAYROLL_DATE_TO,
    name: G.getWindowLocale('titles:date-to', 'Date To'),
  },
  {
    type: 'string',
    value: `${GC.FIELD_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale('titles:payroll-status', 'Payroll Status'),
  },
  {
    type: 'string',
    value: GC.GRC.FLEET_VENDOR_NAME,
    name: G.getWindowLocale('titles:vendor-name', 'Vendor Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICES_COUNT,
    name: G.getWindowLocale('titles:invoices-count', 'Invoices Count'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:deduction', 'Deduction'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel-cards', 'Fuel Cards'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL,
    name: G.getWindowLocale('titles:expenses', 'Expenses'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:additional-payroll-pay', 'Additional Payroll Pay'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_INVOICES_TOTAL,
    name: G.getWindowLocale('titles:total-vendor-trip-charges', 'Total Vendor Trip Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAYROLL_GRAND_TOTAL,
    name: G.getWindowLocale('titles:gross-total', 'Gross Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_DRIVER_PAYROLLS_TOTAL,
    name: G.getWindowLocale('titles:driver-payrolls', 'Driver Payrolls'),
  },
  {
    type: 'string',
    value: GC.FIELD_PAYROLL_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_INTEGRATED_DATE,
    name: G.getWindowLocale('titles:integrated-date', 'Integrated Date'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INVOICES_TEL_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:tel-reference', 'TEL Reference'),
  },
  ...payToFields,
  {
    type: 'number',
    value: GC.GRC.FLEET_VENDOR_TARGET_RPM,
    name: G.getWindowLocale('titles:target-rpm', 'Target RPM'),
  },
  {
    type: 'number',
    disableFilter: true,
    value: GC.FIELD_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:total-trips-distance', 'Total Trips Distance'),
  },
];
