import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature payrolls
import PayrollInvoices from '../components/payroll-invoices';
import { PayrollStatisticWithInvoices } from '../components/payroll-statistic';
//////////////////////////////////////////////////

const commonTableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
};

const payToLocationFields = [
  { name: GC.GRC.PAY_TO_LOCATION_LOCATION_NAME, sequence: 20 },
  { name: GC.GRC.PAY_TO_LOCATION_PAY_TYPE, sequence: 21 },
  { name: GC.GRC.PAY_TO_LOCATION_START_DATE, sequence: 22 },
  { name: GC.GRC.PAY_TO_LOCATION_ADDRESS, sequence: 23 },
  { name: GC.GRC.PAY_TO_LOCATION_ADDRESS2, sequence: 24 },
  { name: GC.GRC.PAY_TO_LOCATION_COUNTRY, sequence: 25 },
  { name: GC.GRC.PAY_TO_LOCATION_STATE, sequence: 26 },
  { name: GC.GRC.PAY_TO_LOCATION_CITY, sequence: 27 },
  { name: GC.GRC.PAY_TO_LOCATION_ZIP, sequence: 28 },
];

export const report = {
  fields: [
    { name: GC.FIELD_PAYROLL_NUMBER, sequence: 1 },
    { name: GC.FIELD_PAYROLL_DATE_FROM, sequence: 2 },
    { name: GC.FIELD_PAYROLL_DATE_TO, sequence: 3 },
    { name: GC.GRC.STATUS_DISPLAYED_VALUE, sequence: 4 },
    { name: GC.GRC.DRIVER_FIRST_NAME, sequence: 5 },
    { name: GC.GRC.DRIVER_LAST_NAME, sequence: 6 },
    { name: GC.FIELD_PAYROLL_INVOICES_COUNT, sequence: 7 },
    { name: GC.FIELD_PAYROLL_GRAND_TOTAL, sequence: 8 },
    { name: GC.FIELD_PAYROLL_MAIN_CHARGES_TOTAL, sequence: 9 },
    { name: GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL, sequence: 10 },
    { name: GC.FIELD_PAYROLL_CHARGES_TOTAL, sequence: 11 },
    { name: GC.FIELD_PAYROLL_INVOICES_TOTAL, sequence: 12 },
    { name: GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL, sequence: 13 },
    { name: GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL, sequence: 14 },
    { name: GC.FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL, sequence: 15 },
    { name: GC.FIELD_PAYROLL_CHECK_NUMBER, sequence: 16 },
    { name: GC.FIELD_PAYROLL_COMMENTS, sequence: 17 },
    { name: GC.FIELD_PAYROLL_CREATED_DATE, sequence: 18 },
    { name: GC.FIELD_PAYROLL_CURRENCY, sequence: 19 },
    ...payToLocationFields,
  ],
};

export const vendorReport = {
  fields: [
    { name: GC.FIELD_PAYROLL_NUMBER, sequence: 1 },
    { name: GC.FIELD_PAYROLL_DATE_FROM, sequence: 2 },
    { name: GC.FIELD_PAYROLL_DATE_TO, sequence: 3 },
    { name: GC.GRC.STATUS_DISPLAYED_VALUE, sequence: 4 },
    { name: GC.GRC.FLEET_VENDOR_NAME, sequence: 5 },
    { name: GC.FIELD_PAYROLL_INVOICES_COUNT, sequence: 6 },
    { name: GC.FIELD_PAYROLL_GRAND_TOTAL, sequence: 7 },
    { name: GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL, sequence: 8 },
    { name: GC.FIELD_PAYROLL_CHARGES_TOTAL, sequence: 9 },
    { name: GC.FIELD_PAYROLL_INVOICES_TOTAL, sequence: 10 },
    { name: GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL, sequence: 11 },
    { name: GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL, sequence: 12 },
    { name: GC.FIELD_DRIVER_PAYROLLS_TOTAL, sequence: 13 },
    { name: GC.FIELD_PAYROLL_CHECK_NUMBER, sequence: 14 },
    { name: GC.FIELD_PAYROLL_COMMENTS, sequence: 15 },
    { name: GC.FIELD_PAYROLL_CREATED_DATE, sequence: 16 },
    { name: GC.FIELD_PAYROLL_CURRENCY, sequence: 17 },
    ...payToLocationFields,
  ],
};

export const getTableSettings = (props: Object) => {
  const {
    reportList,
    filterParams,
    selectedReport,
  } = props;

  const hasPinned = G.hasPinnedReports(reportList);
  const hasSearchCriteria = G.hasSearchCriteria(selectedReport, filterParams);

  let maxHeight = 'calc(100vh - 145px)';

  if (R.and(hasPinned, hasSearchCriteria)) {
    maxHeight = 'calc(100vh - 200px)';
  } else if (R.or(hasPinned, hasSearchCriteria)) {
    maxHeight = 'calc(100vh - 165px)';
  }

  return {
    ...commonTableSettings,
    maxHeight,
    minHeight: 320,
    allowEditBtn: true,
    allowSelectAll: true,
    moreBtnCellWidth: 50,
    expandableItems: true,
    searchableTitles: true,
    checkBoxCellWidth: 120,
    checkBoxCellJustifyContent: 'unset',
    expandedDetailsComponent: (props: Object) => {
      if (R.propEq(GC.VENDOR_PAYROLL_REPORT, GC.FIELD_TYPE, selectedReport)) {
        return <PayrollStatisticWithInvoices {...props} />;
      }

      return <PayrollInvoices {...props} />;
    },
  };
};

export const tableSettingsAsyncPayroll = {
  ...commonTableSettings,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const columnSettings = {
  [GC.FIELD_PAYROLL_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:payroll-number',
  },
  [GC.FIELD_PAYROLL_DATE_FROM]: {
    searchable: true,
    name: 'titles:date-from',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.FIELD_PAYROLL_DATE_TO]: {
    searchable: true,
    name: 'titles:date-to',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    searchable: true,
    name: 'titles:payroll-status',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.STATUS_DISPLAYED_VALUE, data),
        [GC.SYSTEM_DROPDOWN_STATUS, GC.FIELD_DISPLAYED_VALUE],
        data,
      );

      return (
        <TextComponent width={100} title={text} withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.GRC.DRIVER_FIRST_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:driver-first-name',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.DRIVER_FIRST_NAME, data),
        [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME],
        data,
      );

      return (
        <Flex>
          <Box mr='6px'>
            {I.driver(G.getTheme('colors.light.mainDark'))}
          </Box>
          <TextComponent
            width={100}
            title={text}
            withEllipsis={true}
          >
            {text}
          </TextComponent>
        </Flex>
      );
    },
  },
  [GC.GRC.DRIVER_LAST_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:driver-last-name',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.DRIVER_LAST_NAME, data),
        [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME],
        data,
      );

      return (
        <Flex>
          <Box mr='6px'>
            {I.driver(G.getTheme('colors.light.mainDark'))}
          </Box>
          <TextComponent
            width={100}
            title={text}
            withEllipsis={true}
          >
            {text}
          </TextComponent>
        </Flex>
      );
    },
  },
  [GC.GRC.DRIVER_TRUCK_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:truck',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.DRIVER_TRUCK_UNIT_ID, data),
        [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_TRUCK, GC.FIELD_UNIT_ID],
        data,
      );

      if (R.isNil(text)) return null;

      return (
        <Flex>
          <Box mr='6px'>
            {I.truck(G.getTheme('colors.light.mainDark'))}
          </Box>
          <TextComponent
            width={100}
            title={text}
            withEllipsis={true}
          >
            {text}
          </TextComponent>
        </Flex>
      );
    },
  },
  [GC.GRC.FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:vendor-name',
    customComponent: ({ data }: Object) => {
      const text = G.getPropFromObject(GC.GRC.FLEET_VENDOR_NAME, data);

      return (
        <Flex>
          <Box mr='6px'>
            {I.driver(G.getTheme('colors.light.mainDark'))}
          </Box>
          <TextComponent
            width={100}
            title={text}
            withEllipsis={true}
          >
            {text}
          </TextComponent>
        </Flex>
      );
    },
  },
  [GC.FIELD_PAYROLL_CHECK_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:check-number',
  },
  [GC.FIELD_PAYROLL_INVOICES_COUNT]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:invoices-count',
  },
  [GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:deduction',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:additional-payroll-pay',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_CHARGES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_CHARGES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_MAIN_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:main-charges',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_MAIN_CHARGES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_MAIN_CHARGES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_INVOICES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-driver-trip-charges',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_INVOICES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_INVOICES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:fuel-cards',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:toll-charges',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_TOLL_CHARGES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:advance-payment',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:expenses',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_DRIVER_PAYROLLS_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:driver-payrolls',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_DRIVER_PAYROLLS_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_DRIVER_PAYROLLS_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_GRAND_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:gross-total',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_PAYROLL_GRAND_TOTAL, data)) &&
      <TextComponent
        p='4px 6px'
        fontWeight={700}
        borderRadius='3px'
        width='fit-content'
        display='inline-block'
        bg={G.getTheme('colors.light.blue')}
        color={G.getTheme('colors.light.mainLight')}
      >
        {`${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_CURRENCY, data))} ${
          G.toFixed(G.getPropFromObject(GC.FIELD_PAYROLL_GRAND_TOTAL, data))}`}
      </TextComponent>
    ),
  },
  [GC.FIELD_PAYROLL_CURRENCY]: {
    width: 200,
    searchable: true,
    name: 'titles:currency',
  },
  [GC.FIELD_PAYROLL_COMMENTS]: {
    width: 200,
    searchable: true,
    name: 'titles:comments',
  },
  [GC.FIELD_PAYROLL_CREATED_DATE]: {
    type: 'date',
    searchable: true,
    name: 'titles:created-date',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.GRC.INVOICES_TEL_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    searchable: true,
    name: 'titles:tel-reference',
  },
  [GC.GRC.PAY_TO_LOCATION_START_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:pay-to', 'titles:start-date'],
  },
  [GC.GRC.PAY_TO_LOCATION_LOCATION_NAME]: {
    width: 200,
    name: ['titles:pay-to', 'titles:name'],
  },
  [GC.GRC.PAY_TO_LOCATION_PAY_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:pay-to', 'titles:pay-type'],
  },
  [GC.GRC.PAY_TO_LOCATION_ADDRESS]: {
    width: 200,
    name: ['titles:pay-to', 'titles:address'],
  },
  [GC.GRC.PAY_TO_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:pay-to', 'titles:address2'],
  },
  [GC.GRC.PAY_TO_LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:pay-to', 'titles:country'],
  },
  [GC.GRC.PAY_TO_LOCATION_STATE]: {
    width: 200,
    name: ['titles:pay-to', 'titles:state'],
  },
  [GC.GRC.PAY_TO_LOCATION_CITY]: {
    width: 200,
    name: ['titles:pay-to', 'titles:city'],
  },
  [GC.GRC.PAY_TO_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:pay-to', 'titles:zip'],
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    pivotType: 'number',
    type: GC.FIELD_DISTANCE,
    name: 'titles:total-trips-distance',
  },
  [GC.FIELD_TOTAL_RATE_DISTANCE]: {
    width: 200,
    pivotType: 'number',
    type: GC.FIELD_DISTANCE,
    name: 'titles:total-rates-distance',
  },
  [GC.GRC.FLEET_VENDOR_TARGET_RPM]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:target-rpm',
  },
  [GC.FIELD_INTEGRATED_DATE]: {
    searchable: true,
    name: 'titles:integrated-date',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.pathOr(' ', [GC.GRC.ACCOUNTING_INTEGRATION_STATUS], data);
      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);

      return (
        <span title={text}>{text}</span>
      );
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.GRC.DRIVER_FULL_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:driver-full-name',
  },
  [GC.GRC.DRIVER_FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:vendor-name',
  },
};
